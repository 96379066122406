<template>
  <div class="box">
    <van-cell-group>
      <van-form>
        <van-field
          v-model="form.number"
          name="箱编号"
          label="箱编号"
          placeholder="请输入"
          disabled
        />
        <van-field
          readonly
          clickable
          name="picker"
          :value="form.progressName"
          label="人员类型"
          placeholder="请选择"
          @click="onChooseType"
        />
        <van-field
          v-model="form.boxAddress"
          rows="2"
          type="textarea"
          :readonly="!canTypeIds.includes(4)"
          autosize
          maxlength="30"
          show-word-limit
          name="安装地址"
          label="安装地址"
          placeholder="请输入"
        />
        <van-field name="uploader" label="现场照片" style="padding-bottom: 34px;">
          <template #input>
            <van-uploader
              v-model="fileList"
              :max-count="1"
              accept="image/*"
              :max-size="5 * 1024 * 1024"
              :after-read="afterRead"
              @oversize="handleOversize"
              @delete="handleDelete"
            />
            <div class="tips">请上传一张现场安装完成的照片</div>
          </template>
        </van-field>
        <div style="margin: 16px">
          <van-button block type="info" @click="onSubmit"
            >提交</van-button
          >
        </div>
      </van-form>
    </van-cell-group>
    <!-- 人员类型选择器 -->
    <van-action-sheet v-model="show" title="" :closeable="false">
      <van-picker
        show-toolbar
        title=""
        value-key="name"
        :columns="canSelectTypes"
        :default-index="0"
        @confirm="onConfirm"
        @cancel="onCancel"
      />
    </van-action-sheet>
  </div>
</template>

<script>
import { getQiniuToken, updateboxProgress, getProgressInfo } from "@/api/box";
import { Toast } from "vant";

export default {
  data() {
    return {
      form: {
        number: "",
        progressType: "",
        imageKey: "",
        boxAddress: '',
        progressName: ''
      },
      show: false,
      types: [
        { name: "箱体安装", value: 4 },
        { name: "网络运营", value: 1 },
        { name: "电力运营", value: 2 },
        { name: "安装调试", value: 3 },
      ],
      canIds: [],
      fileList: [],
      token: "",
    };
  },
  computed: {
    canSelectTypes() {
      const arr = [];
      this.canIds.map((item) => {
        arr.push(this.types.filter((tItem) => tItem.value == item)[0]);
      });
      return arr;
    },
    canTypeIds() {
      const arr = this.$route.query.can.split(',').map(item => Number(item))
      return arr
    }
  },
  async created() {
    this.getToken();
    this.form.number = this.$route.query.number
    await this.getInfo()
    this.canIds = this.$route.query.can.split(',')
  },
  methods: {
    async getInfo() {
      const { id, number } = this.$route.query
      const res = await getProgressInfo(id, number)
      this.form.boxAddress = res.data.boxAddress
    },
    getTypeNameByValue(value) {
      return this.types.filter((tItem) => tItem.value === value)[0].name;
    },
    // 人员类型
    onChooseType() {
      this.show = true;
    },
    onConfirm(value, index) {
      console.log(index)
      this.form.progressType = value.value;
      this.form.progressName = this.getTypeNameByValue(this.form.progressType)
      this.show = false;
    },
    onCancel() {
      this.show = false;
    },
    // 现场图片
    afterRead(file) {
      this.$axios({
        method: "POST",
        url: "//up-cn-east-2.qiniup.com",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: {
          token: this.token,
          file: file.file,
        },
      }).then((res) => {
        this.form.imageKey = res.data.key;
      },(err) => {
        console.log(err)
        Toast("图片上传失败");
      });
    },
    handleOversize(file) {
      if (file.file.name) {
        Toast("图片大小不超过5M");
      }
    },
    handleDelete() {
      this.form.imageKey = '';
    },
    async getToken() {
      const res = await getQiniuToken();
      this.token = res.data;
    },
    // 提交
    async onSubmit() {
      const { number, progressType, imageKey, boxAddress } = this.form;
      if (!boxAddress) {
        Toast("请输入安装地址");
        return;
      }
      if (!progressType) {
        Toast("请选择人员类型");
        return;
      }
      if (!imageKey) {
        Toast("请上传现场图片");
        return;
      }
      const params = {
        imageKey,
        number,
        progressType,
        boxAddress,
        qrCodeId: this.$route.query.id
      }
      await updateboxProgress(params)
      Toast("已提交");
      setTimeout(()=> {
        this.onReset()
      }, 500)
    },
    // 重置
    onReset() {
      this.form = {
        number: "",
        progressType: "",
        progressName: "",
        imageKey: "",
      }
      this.canIds = [],
      this.fileList = []
      this.$router.push({ path: '/box', query: { id: this.$route.query.id }})
    }
  },
};
</script>

<style lang="less" scoped>
.box {
  min-height: 100vh;
  padding-top: 15px;
  box-sizing: border-box;
  background: #fff;
  ::v-deep .van-cell {
    padding: 16px;
  }
  ::v-deep .van-field__label {
    color: #323233;
    font-size: 17px;
  }
  ::v-deep .van-field__value {
    font-size: 17px;
    text-align: left;
  }
  ::v-deep .van-field__control {
    color: #969799;
    text-align: left;
  }
  ::v-deep .van-cell__title {
    font-size: 17px;
  }
  ::v-deep .van-cell__value {
    font-size: 17px;
  }
  .tips {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #8596A6;
    position: absolute;
    bottom: -24px;
  }
}
::v-deep .van-field__control--right {
  margin-right: 16px;
}
</style>